
import Vue from 'vue';
import analytics from '../../mixins/analytics';
import utils from '../../util';
import HeaderLogoC360 from '../components/toolbar/headerLogoC360.vue';
import { C360Provider, defineAbilityFor, useLink } from '@c360/ui';

export default Vue.extend({
  components: {
    HeaderLogoC360,
    AppGlobals: () => import('../global/globals.vue'),
    C360Footer: () => import('../global/footerC360.vue'),
    C360Provider,
  },
  data(): {
    error: string | undefined;
    loading: boolean;
    tokenLoading: boolean;
    errorMessage: string | null;
    emailLink: string;
    refreshToken: string;
  } {
    return {
      error: '',
      loading: false,
      tokenLoading: false,
      errorMessage: null,
      emailLink: 'mailto: support@sbganalytics.com',
      refreshToken: '',
    };
  },
  mixins: [analytics],
  computed: {
    accessToken() {
      return localStorage.getItem('ah:accessToken');
    },
    refreshTokenLS() {
      return localStorage.getItem('ah:refreshToken');
    },
    ability() {
      return defineAbilityFor({
        isSuperUser: this.$store.state?.customer?.user?.is_superuser ?? false,
        isAgencyAdmin: utils.hasRight(this.$store.state?.customer?.user, ['AGENCY_ADMIN']) ?? false,
        products: this.$store.state?.customer?.user?.products ?? [],
        permissions: this.$store.state?.customer?.user?.permissions ?? [],
        activeAgencyName: this.$store.state?.customer?.user?.active_agency_id ?? '',
        tenantsCount: this.$store.state?.customer?.user?.AvailableAgencies?.length ?? 1,
      });
    },
    c360Path(): string {
      return `${window.location.protocol}//${window.location.host}${this.$route.fullPath}`;
    },
    errorMessageDialog(): boolean {
      return this.errorMessage != null;
    },
    notFoundInDSError(): boolean {
      return this.error.startsWith('Requester user') && this.error.includes('not found.');
    },
    showLoginButton(): boolean {
      return this.error.includes('system error') || this.error.includes('refresh token expired');
    },
    hasNoAccess(): boolean {
      return this.error.includes('no access');
    },
    backgroundColor(): object {
      return { 'background-color': 'var(--c360-background-primary)' };
    },
    redirectLink(): string {
      const agency = utils.getAgencyFromURL();
      return useLink({
        product: 'compulse360',
        agency,
        env: utils.getEnvForProductLink(),
      });
    },
  },
  mounted() {
    this.refreshToken = this.$store.state?.customer?.user?.refreshToken;

    if (this.$route.query?.token) {
      this.handleToken(this.$route.query.token as string);
    }
    if (this.$route.query?.nextUrl) {
      localStorage.setItem('nextUrl', this.$route.query.nextUrl);
    } else {
      localStorage.removeItem('nextUrl');
    }
  },
  methods: {
    goToPortalLogin(): void {
      localStorage.removeItem('hasUpdatedHomeCache');
      localStorage.removeItem('ah:products');
      localStorage.removeItem('ah:jwtToken');
      localStorage.removeItem('ah:refreshToken');
      localStorage.removeItem('ah:accessToken');
      window.location.href = `${this.redirectLink}/login`;
      return;
    },
    handleRedirect(): void {
      const redirectURI = this.$route.query.redirectURI || this.$route.query.redirectUri;
      if (redirectURI) {
        window.location.href = decodeURIComponent(redirectURI as string);
      } else {
        this.$router.push({ name: 'login' });
      }
    },
    handleLogout(): void {
      this.$router.push({
        name: 'logout',
        query: { token: this.$route.query.token },
        params: { notFoundInDSError: this.notFoundInDSError },
      });
      this.analyticTrack(this.trackValue.EVENT_NAV, this.trackValue.TRACK_NAV_LOGOUT);
    },
    handleToken(token: string): void {
      this.tokenLoading = true;
      this.$store
        .dispatch('ahlogin', { token, domain: window.location.hostname })
        .then(user => {
          if (!user.email) {
            this.tokenLoading = false;
            this.error = user.error || 'ahlogin failed';
            this.ahLoginTrack(this.trackValue.AH_LOGIN, token, 'Login failed');
            return;
          }
          localStorage.setItem('ah:products', JSON.stringify(user.products));
          // TODO: rename
          localStorage.setItem('ah:jwtToken', token); // short refresh
          localStorage.setItem('ah:refreshToken', user.refreshToken); // full refresh
          localStorage.setItem('ah:accessToken', user.accessToken); // full access

          this.ahLoginTrack(this.trackValue.AH_LOGIN, token, 'Successful login');
          // don't reset this.tokenLoading, so it doesn't flash the login box before navigating away
          this.handleRedirect();
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.ahLoginTrack(this.trackValue.AH_LOGIN, token, 'Login failed');
          this.tokenLoading = false;
          // eslint-disable-next-line no-console
          console.error('ahlogin error', error);
        });
    },
  },
  watch: {
    '$store.state.customer.user.accessToken': {
      handler(newState: boolean): void {
        this.refreshToken = newState;
      },
    },
  },
});
